import React, { useEffect } from "react";
import Aos from "aos";
import { Card, Button } from "react-bootstrap";
import { ReactComponent as ReactSvg } from "../assets/react-2.svg";
import { ReactComponent as FreeCodeCamp } from "../assets/freeCodeCamp.svg";
import { ReactComponent as Brand } from "../assets/brand.svg";
import { ReactComponent as Boot } from "../assets/bootstrap-5-1.svg";
import { ReactComponent as Js } from "../assets/javascript-1.svg";

const CardComp = ({ header, SvgName, Dark, href }) => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out-cubic",
    });
  }, []);
  function svgDeterminant(name) {
    if (name === "ReactSvg") {
      return <ReactSvg className="skill-svg" />;
    }
    if (name === "freeCodeCamp") {
      return <FreeCodeCamp className="skill-svg" />;
    }
    if (name === "brand") {
      return <Brand className="skill-svg" />;
    }
    if (name === "js") {
      return <Js className="skill-svg" />;
    }
    if (name === "boot") {
      return <Boot className="skill-svg" />;
    }
  }
  return (
    <Card
      className={Dark ? "flex-box m-3 p-5  bg-dark" : "flex-box  m-3 p-5"}
      id="mycard"
      data-aos="zoom-in"
    >
      {svgDeterminant(SvgName)}
      <hr />
      <Card.Title className={Dark ? `text-white` : `text-dark`}>
        {header}
      </Card.Title>
      <hr />
      <Button
        className="px-4"
        size={"lg"}
        href={href}
        variant={`${Dark ? "outline-light" : "outline-dark"}`}
      >
        Check
      </Button>
    </Card>
  );
};

export default CardComp;
