import React, { useEffect } from "react";
import Aos from "aos";

const Text = ({ header, para, p1, p2, p3 }) => {
  useEffect(() => {
    Aos.init({
      duration: 500,
      easing: "ease-in-out-quad",
    });
  }, []);
  return (
    <div className="text-white p-5">
      <h1 className="spl-text text-center" data-aos="zoom-in">
        {header}
      </h1>
      <hr />
      <p>{para}</p>
      <ul>
        <li>{p1}</li>
        <li>{p2}</li>
        <li>{p3}</li>
      </ul>
    </div>
  );
};

export default Text;
