import React, { useState } from "react";
import CompNavbar from "./navbar";
import Slider from "./carousel";
import Banner from "./banner";
import Primary from "./primary";
import Skills from "./skills";
import Footer from "./footer";
import SVGSR from "../assets/svg";
import SVG from "../assets/svgrev";
import Portfolio from "./portfolio";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [Dark, setDark] = useState(false);
  return (
    <div className={Dark ? "bg-pitch-dark" : "bg-light"}>
      <CompNavbar Dark={Dark} setDark={setDark} />
      <Slider/>
      <Banner />
      <Skills Dark={Dark} />
      <SVG />
      <Portfolio Dark={Dark}/>
      <SVGSR />
      <Primary />
      <SVGSR />
      <Footer />
    </div>
  );
};

export default App;
