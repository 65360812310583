import React, { useState, useEffect } from "react";
import Proto from "./proto";
import {  ButtonGroup, Button } from "react-bootstrap";
import Aos from "aos";

const Portfolio = ({ Dark }) => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: "ease-in-out-sine",
    });
  }, []);
  const [StatusQuo, setStatusQuo] = useState("Projects");
  const projectInfo = [
    ["Dictionary Application", "Crypto Dashboard"],
    [
      "https://affectionate-meitner-9413a9.netlify.app",
      "https://benjamin.vercel.app/",
    ],
  ];
  const CertInfo = [
    ["JS Developer", "Web Designing"],
    [
      "https://www.freecodecamp.org/certification/manjunathas/javascript-algorithms-and-data-structures",
      "https://www.freecodecamp.org/certification/manjunathas/responsive-web-design",
    ],
  ];
  return (
    <div id="projects">
      <h1 className="spl-text text-center" data-aos="fade-down">
        Portfolio
      </h1>
      {/*Button group */}
      <div className="flex-box ">
        <ButtonGroup className="purple-bg flex-box-x p-4">
          <Button
            variant="outline-light"
            className="px-5"
            size="lg"
            data-aos="slide-up"
            onClick={() => {
              setStatusQuo("Projects");
            }}
          >
            Projects
          </Button>
          <Button
            variant="outline-light"
            className="px-5"
            size="lg"
            data-aos="slide-up"
            onClick={() => {
              setStatusQuo("Certifications");
            }}
          >
            Certifications
          </Button>
        </ButtonGroup>
      </div>
      {/*Button group */}

      <div className="displaying">
        {StatusQuo === "Projects" ? (
          <Proto props={projectInfo} Dark={Dark} svgName="brand" />
        ) : (
          <Proto props={CertInfo} Dark={Dark} svgName="freeCodeCamp" />
        )}
      </div>
    </div>
  );
};

export default Portfolio;
