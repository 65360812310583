import React from "react";
import { Row, Col } from "react-bootstrap";
import CardComp from "./card";

const Proto = ({ props, Dark, svgName }) => {
  let i = -1;
  return (
    <div>
      <Row className="px-5 my-2">
        {props.map(() => {
          i += 1;
          return (
            <Col sm={12} lg={6} key={i}>
              <CardComp
                header={props[0][i]}
                href={props[1][i]}
                Dark={Dark}
                SvgName={svgName}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default Proto;
