import React from "react";

const SVGSR = () => {
  return (
    <svg
      height="50%"
      width="100%"
      id="svg"
      viewBox="0 0 1440 400"
      xmlns="http://www.w3.org/2000/svg"
      className="transition duration-300 ease-in-out delay-150"
    >
      <defs>
        <linearGradient id="gradient">
          <stop offset="5%" stopColor="#7f53ac88"></stop>
          <stop offset="95%" stopColor="#647dee88"></stop>
        </linearGradient>
      </defs>
      <path
        d="M 0,400 C 0,400 0,133 0,133 C 102.07655502392345,134.8755980861244 204.1531100478469,136.7511961722488 295,138 C 385.8468899521531,139.2488038277512 465.464114832536,139.8708133971292 568,149 C 670.535885167464,158.1291866028708 795.9904306220096,175.76555023923441 884,183 C 972.0095693779904,190.23444976076559 1022.5741626794259,187.06698564593302 1109,177 C 1195.4258373205741,166.93301435406698 1317.712918660287,149.9665071770335 1440,133 C 1440,133 1440,400 1440,400 Z"
        stroke="none"
        strokeWidth="0"
        fill="url(#gradient)"
        className="transition-all duration-300 ease-in-out delay-150"
      ></path>
      <defs>
        <linearGradient id="gradient">
          <stop offset="5%" stopColor="#7f53acff"></stop>
          <stop offset="95%" stopColor="#647deeff"></stop>
        </linearGradient>
      </defs>
      <path
        d="M 0,400 C 0,400 0,266 0,266 C 76.93779904306217,272.0287081339713 153.87559808612434,278.0574162679426 246,271 C 338.12440191387566,263.9425837320574 445.43540669856463,243.79904306220095 559,248 C 672.5645933014354,252.20095693779905 792.3827751196171,280.7464114832536 880,277 C 967.6172248803829,273.2535885167464 1023.0334928229665,237.2153110047847 1111,230 C 1198.9665071770335,222.7846889952153 1319.4832535885166,244.39234449760767 1440,266 C 1440,266 1440,400 1440,400 Z"
        stroke="none"
        strokeWidth="0"
        fill="url(#gradient)"
        className="transition-all duration-300 ease-in-out delay-150"
      ></path>
    </svg>
  );
};

export default SVGSR;
