import React from "react";

const SVG = () => {
  return (
    <svg
      height="100%"
      width="100%"
      id="svg"
      viewBox="0 0 1440 400"
      xmlns="http://www.w3.org/2000/svg"
      className="transition duration-300 ease-in-out delay-150"
    >
      <defs>
        <linearGradient id="gradient">
          <stop offset="0%" stopColor="#7f53acff"></stop>
          <stop offset="74%" stopColor="#647deeff"></stop>
        </linearGradient>
      </defs>
      <path
        d="M 0,400 C 0,400 0,200 0,200 C 141.85714285714283,184.5 283.71428571428567,169 413,172 C 542.2857142857143,175 659,196.5 749,185 C 839,173.5 902.2857142857142,129 1013,126 C 1123.7142857142858,122.99999999999999 1281.857142857143,161.5 1440,200 C 1440,200 1440,400 1440,400 Z"
        stroke="none"
        stopwidth="0"
        fill="url(#gradient)"
        className="transition-all duration-300 ease-in-out delay-150"
        transform="rotate(-180 720 200)"
      ></path>
    </svg>
  );
};

export default SVG;
