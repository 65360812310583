import React from "react";
import { Carousel } from "react-bootstrap";
import { ReactComponent as BanSVG } from "../assets/bannerImg.svg";
import { ReactComponent as DesSVG } from "../assets/des.svg";
import { ReactComponent as DevSVG } from "../assets/dev.svg";

const Slider = () => {
  return (
    <Carousel fade>
      <Carousel.Item className="carousel" interval={1500}>
        <BanSVG className="img mx-auto d-block" />
        <Carousel.Caption>
          <h1>Hello there,Manjunath here</h1>
          <p>Know what i can do for you</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="carousel" interval={1500}>
        <DesSVG className="img mx-auto d-block" />

        <Carousel.Caption>
          <h1>Amazing Design</h1>
          <p>
            Compliant with the 'next-new-thing' while prioritizing accessibility
            and user experience
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="carousel" interval={1500}>
        <DevSVG className="img mx-auto d-block" />

        <Carousel.Caption>
          <h1>Efficient Development</h1>
          <p>
            SCRUM based development with design patterns for a Efficient and
            effective software build to suit your needs
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
