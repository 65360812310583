import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Text from "./Text";

const Primary = () => {
  const [Selected, setSelected] = useState("");
  const displayDeterminant = () => {
    if ((Selected === "why") | (Selected === "")) {
      return (
        <Text
          header={"Why Me?"}
          para={
            "Now,that's a great start. Well, I am a developer with immense passion for what i do and could go on for hours on end even without noticing how time has slipped by. I won't brag about me technical achievement,you can see that by yourself in my portfolio, to sum up .."
          }
          p1="IMMENSE passion"
          p2="Strong technical knowledge"
          p3="2 years of hands-on experience"
        />
      );
    }

    if (Selected === "contact") {
      return (
        <Text
          header={"Contact"}
          para={"If you're still contemplating as to wether or not to contact me,pease go ahead. I am always looking forward for being a part of a good project along with great people.I am available (anytime) at these platforms"}
          p1="Facebook"
          p2="Instagram"
          p3="Twitter"
        />
      );
    }
    if (Selected === "about") {
      return (
        <Text
          header={"About me"}
          para={"I am a passionate developer who loves to build software that adds value to people's lives.I am currently a student and a traveller on the exciting journey of software development.Here are some of my interests.."}
          p1="Music and language enthusiast"
          p2="Public Speaking"
          p3="Finance 
and Economics"
        />
      );
    }
  };
  return (
    <div className="my-0 mx-5 py-5" id="primary">
      <Row>
        <Col sm={12} lg={3} className="purple-bg">
          <Row className="p-5 ">
            <Col sm={4} lg={12}>
              <Button
                variant={"outline-light"}
                size={"lg"}
                className="m-2"
                onClick={() => {
                  setSelected("why");
                }}
              >
                Why me?
              </Button>
            </Col>
            <Col sm={4} lg={12}>
              <Button
                variant={"outline-light"}
                size={"lg"}
                className="m-2"
                onClick={() => {
                  setSelected("contact");
                }}
              >
                Let's build
              </Button>
            </Col>
            <Col sm={4} lg={12}>
              <Button
                variant={"outline-light"}
                size={"lg"}
                className="m-2"
                onClick={() => {
                  setSelected("about");
                }}
              >
                About me
              </Button>
            </Col>
          </Row>
        </Col>
        <Col sm={12} lg={9} className="bg-dark">
          {displayDeterminant()}
        </Col>
      </Row>
    </div>
  );
};

export default Primary;
