import React, { useEffect } from "react";
import Aos from "aos";
import { Button } from "react-bootstrap";


const Banner = () => {
  useEffect(() => {
    Aos.init({
      duration: 1200,
      easing: "ease-in-out-sine",
    });
  }, []);
  return (
    <div className="banner flex-box p-5">
      <h1 className="text-light txt" id="anchor">
        Where{"  "}
        <span data-aos="fade-in-up" data-aos-anchor="#anchor">
          Passion
        </span>{"  "}
        builds the{"  "}
        <span data-aos="fade-in-up" data-aos-anchor="#anchor">
          Future
        </span>
      </h1>
      <Button
        variant="outline-light"
        className="btn px-5"
        size="lg"
        href="#primary"
      >
        Explore
      </Button>
    </div>
  );
};

export default Banner;
