import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { ReactComponent as Brand } from "../assets/brand.svg";
import "../styles.css";

const CompNavbar = ({ Dark, setDark }) => {
  const [isDark, setisDark] = useState(false);
  const handleDark = (e) => {
    e.preventDefault();
    setDark(!Dark);
    setisDark(!isDark);
    console.log("mode changed");
  };
  return (
    <Navbar expand="lg" collapseOnSelect className="p-2 navbar">
      <Navbar.Toggle aria-controls="#res-nav"></Navbar.Toggle>
      <Navbar.Brand
        href="#primary"
        className={Dark ? `text-white` : `text-dark`}
      >
        <Brand /> Dev@Man
      </Navbar.Brand>
      <Navbar.Collapse id="res-nav" className="justify-content-end">
        <Nav>
          <Nav.Link href="/">
            <i
              className={
                isDark ? `bi bi-cloud-moon-fill` : `bi bi-cloud-sun-fill`
              }
              onClick={(e) => {
                handleDark(e);
              }}
            ></i>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CompNavbar;

/*
<Navbar
      expand="lg"
      bg="light"
      variant="light"
      collapseOnSelect
      className="p-3 purple-bg"
    >
      <Navbar.Toggle aria-controls="res-navbar" />
      <Navbar.Collapse id="res-navbar" className="justify-content-end">
        <Nav.Link href="/">
          <Nav.Link href="/" >
            <i className="bi bi-house-fill"></i>
          </Nav.Link>
          <Nav.Link href="/">
            <i className="bi bi-file-earmark-person-fill"></i>
          </Nav.Link>
          <Nav.Link href="/">
            <i className="bi bi-terminal"></i>
          </Nav.Link>
          <Nav.Link href="/">
            <i className="bi bi-cloud-sun-fill"></i>
          </Nav.Link>
        </Nav.Link>
      </Navbar.Collapse>
    </Navbar>
    */
