import React from "react";
import { Container } from "react-bootstrap";

const Footer = () => {
  return (
    <Container
      className="mx-auto b-block  text-secondary p-4"
      fluid
      fixed="bottom"
      style={{ backgroundColor: "#000" }}
    >
      <Container className="  flex-box flex-x" fluid>
        <a
          href="https://www.facebook.com/profile.php?id=100056465092688"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bi bi-facebook"></i>
        </a>
        <a
          href="https://www.instagram.com/developer.manjunath"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bi bi-instagram"></i>
        </a>
        <a
          href="https://twitter.com/Manjuna73408661"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bi bi-twitter"></i>
        </a>

        <a
          href="https://github.com/CodeJockey444/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bi bi-github"></i>
        </a>
      </Container>
      <Container className="pt-4">
        <h5 className="text-center">
          &copy; All rights reserved.
          <a
            href="/"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            View license
          </a>
        </h5>
      </Container>
    </Container>
  );
};

export default Footer;
