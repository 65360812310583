import React, { useEffect } from "react";
import CardComp from "./card";
import Aos from "aos";
import { Row, Col } from "react-bootstrap";
const Skills = ({ Dark }) => {
  useEffect(() => {
    Aos.init({
      duration: 1400,
      easing: "ease-in-out-quad",
    });
  }, []);

  return (
    <div className="m-2 p-5" id="#skills">
      <h1 className="text-center spl-text" data-aos="fade-up">
        Skills
      </h1>
      <Row>
        <Col sm={12} lg={4} data-aos="fade-in">
          <CardComp
            header={"React"}
            SvgName={"ReactSvg"}
            Dark={Dark}
            href="#projects"
          />
        </Col>
        <Col sm={12} lg={4} data-aos="fade-up">
          <CardComp
            header={"Bootstrap"}
            SvgName={"boot"}
            Dark={Dark}
            href="#projects"
          />
        </Col>
        <Col sm={12} lg={4} data-aos="fade-left">
          <CardComp
            header={"Javascript"}
            SvgName={"js"}
            Dark={Dark}
            href="#projects"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Skills;
